import { marketingMoneyDonationList, marketingMoneyDonationPayfastOnsitePaymentList } from "portals/widgets/components/actions/marketing_money_donation"


export const payfastOnsitePayment = (dispatch, payment_params, successfulDonation, cancelDonation, paymentError) => {
    let donation = marketingMoneyDonationList.getObject(payment_params.id)
    return dispatch(marketingMoneyDonationPayfastOnsitePaymentList.getUniquePaymentIdentifier(payment_params)).then(
        (result) => {
            if (result && result.id) {
                window.payfast_do_onsite_payment({'uuid': result.uuid}, (res) => {
                    if (res === true) {
                        donation.status = 'paid'
                        successfulDonation()
                        dispatch(marketingMoneyDonationList.saveObject(donation))
                    } else {
                        donation.status = 'cancelled'
                        cancelDonation()
                        dispatch(marketingMoneyDonationList.saveObject(donation))
                    }
                })
            } else {
                paymentError()
            }
        }
    )
}

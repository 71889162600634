import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory, Link } from 'react-router-dom'
import { get, includes } from 'lodash'
import websiteTheme from 'themes/websiteTheme'
import { ThemeProvider } from '@material-ui/styles'
import { makeStyles, Button, Grid, Card, CardContent, Typography, List, ListItem, ListItemIcon, ListItemText, CircularProgress } from '@material-ui/core'
import { marketingMoneyDonationList } from '../actions/marketing_money_donation'
import { handleSubmitResult, shouldShowOnDemandSaveButton } from 'actions/form'
import {showSuccess, showError} from 'actions/Error'
import SourceAttribution from './SourceAttribution'
import Alert from '@material-ui/lab/Alert'
import LinkIcon from '@material-ui/icons/Link'
import MapIcon from '@material-ui/icons/Map'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined'

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fadf00',
    minHeight: '100vh',
    padding: 15
  },
  contentContainer: {
    width: 600,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}))

export default function Payment() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const referrer = document.referrer
  const [redirecting, setRedirecting] = useState(false)

  const history = useHistory()
  let { id, status } = useParams()

  const donation_id = id
  const allowed_statuses = ['success', 'cancelled']
  let allowed_status = false

  useEffect(() => {
      async function fetchStaticObjects() {
          is_loading = dispatch(marketingMoneyDonationList.ensureObjectLoaded(donation_id))
          dispatch(marketingMoneyDonationList.fetchListIfNeeded())
      }
      fetchStaticObjects()
  }, [donation_id])

  const is_busy =  useSelector(() => marketingMoneyDonationList.getIsSavingObject())
  const donation = useSelector(() => marketingMoneyDonationList.getObject(donation_id))
  if(includes(allowed_statuses, status) && (get(donation, "status") != 'paid')) {
    allowed_status = status
  }

  let is_loading = donation_id && !get(donation, "id")
  const source_slug = get(donation, "source_slug")

  const renderDirectAccessForbidden = () => {
    return(
      <Alert severity="error">Direct access to this page is not allowed</Alert>
    )
  }

  const updatePaymentStatus = (donation) => {
      const on_ok = function(json) {
          showSuccess("Saved", `Payment ${status}`)
      }
      const values = Object.assign({}, donation, {status: allowed_status })
      return dispatch(marketingMoneyDonationList.saveObject(values)).then((res) => on_ok(res))
  }

  const renderThankYou = () => {
    return(
      <Alert severity="success">Congratulations for making a difference. Together we can end hunger in South Africa</Alert>
    )
  }

  const redirectDonatePage = () => {
    history.push(`/donate/${get(donation, 'id')}`)
  }

  const renderCancelled = () => {
    return(
      <Alert severity="error" style={{marginBottom: 15}}>
          Oh no {get(donation, ["donator", "first_name"])}, your payment was cancelled.&nbsp;
          <div style={{marginLeft: 'auto', marginTop: 15}}>
            <Button size="small" onClick={redirectDonatePage}>Click here to try again</Button>
          </div>
      </Alert>
    )
  }

  const redirectToWebsite = () => {
    setRedirecting(true)
    location.href ="https://saharvest.org"
  }

  const renderNextSteps = () => {
    return(
      <Card>
        <CardContent>
          <Typography variant="p">Where would you like to go next?</Typography>
          <List component="nav">
            <ListItem button onClick={redirectToWebsite}>
              <ListItemIcon>
                <LanguageOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="SA Harvest Website" />
            </ListItem>
          </List>
          { redirecting && <><CircularProgress /> One moment please. We are redirecting you now...</> }
        </CardContent>
      </Card>
    )
  }

  return(
    <ThemeProvider theme={websiteTheme}>
      <div className={classes.root}>
        <div className={classes.contentContainer}>
          {source_slug && <SourceAttribution source_slug={source_slug} /> }
          {!get(donation, 'id') && renderDirectAccessForbidden()}
          {status === 'success' && renderThankYou()}
          {status === 'cancelled' && renderCancelled()}
          {renderNextSteps()}
        </div>
      </div>
    </ThemeProvider>
  )
}

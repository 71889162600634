import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { map } from "lodash";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import moment from 'moment'
import {showSuccess, showError} from 'actions/Error'
import AdminMainLayout from "../layout/AdminMainLayout";
import { FormikDateTimePicker } from "components/form/DatetimePicker";
import { FormikDropdownField } from "components/form/Dropdown";
import { adminDynamicReportGenerator } from "../../../actions/dynamic_reporting"
import { FormikDonorAutoCompleteDropdown } from "../donors/form/DonorAutoCompleteDropdown"
import {
  Typography,
  Grid,
  Button,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { capitalCamel } from "../../../components/CapitalCamel";
import { LoadingModal } from '../../../components/layout/Modal'

export const validationSchema = Yup.object().shape({
  donor: Yup.string().required("Required"),
  start_date: Yup.string().when("period", {is: (val) => val === "date_range", then: Yup.string().required("Required")}),
  end_date: Yup.string().when("period", {is: (val) => val === "date_range", then: Yup.string().required("Required")}),
  select_date: Yup.string().when("period", {is: (val) => val in ["weekly", "monthly"], then: Yup.string().required("Required")}),
});

export default function AdminBeneficiaryDynamicReport(props) {
  const dispatch = useDispatch()
  const history = useHistory();
  const { is_loading, is_busy } = props;
  const [submittingForm, setSubmittingForm] = useState(false)
  const report_type_options = ["weekly", "monthly", "date_range"];
  const [reportType, setReportType] = useState("weekly");
  
  const onFormSubmit = (values) => {
    const on_done = (json) => {
      setSubmittingForm(false)
      if (json.status === 'success') {
        showSuccess("Report generated successfully")
        history.push({
          pathname: "/admin/reports/dynamic_report",
        })
      } else {
        showError("Report generation failed")
      }
    }

    setSubmittingForm(true)

    if (values.start_date) {
      values.start_date = moment(values.start_date).toISOString()
    }
    if (values.end_date) {
      values.end_date = moment(values.end_date).toISOString()
    }
    if (values.select_date) {
      values.select_date = moment(values.select_date).toISOString()
    }
    
    dispatch(adminDynamicReportGenerator.generate(values)).then(on_done)
  };

  const handleChange = (event) => {
    setReportType(event.target.value);
  };

  const renderCheckboxes = () => {
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">Report Type</FormLabel>
        <RadioGroup
          row
          aria-label="report type"
          name="reportType"
          value={reportType}
          onChange={handleChange}
        >
          {map(report_type_options, (r) => {
            return (
              <FormControlLabel
                value={r}
                control={<Radio color="primary" />}
                label={capitalCamel(r)}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  };

  const renderDateRangeBeneficiaryDynamicReport = () => {
    return (
      <Formik
        initialValues={{ period: "date_range" }}
        onSubmit={onFormSubmit}
        enableReinitialize={true}
        validationSchema={validationSchema}
      >
        {(formik_props) => (
          <Form>
            <Typography variant="h6">Date range report</Typography>
            {renderDonorDropdownInput({ props: formik_props })}

            <FormikDateTimePicker
              name="start_date"
              label="From"
              helperText="Select start of reporting range"
              include_time={false}
              formik_props={formik_props}
            />
            <FormikDateTimePicker
              name="end_date"
              label="To"
              helperText="Select end of reporting range"
              include_time={false}
              formik_props={formik_props}
            />

            <Button type="submit" variant="contained" color="primary">
              Generate
            </Button>
          </Form>
        )}
      </Formik>
    );
  };

  const renderMonthlyBeneficiaryDynamicReport = () => {
    return (
      <Formik
        initialValues={{ period: "monthly" }}
        onSubmit={onFormSubmit}
        enableReinitialize={true}
        validationSchema={validationSchema}
      >
        {(formik_props) => (
          <Form>
            <Typography variant="h6">Monthly report</Typography>
            {renderDonorDropdownInput({ props: formik_props })}

            <FormikDateTimePicker
              name="select_date"
              label="Select date..."
              helperText="Select any date in the reporting month"
              include_time={false}
              formik_props={formik_props}
            />

            <Button type="submit" variant="contained" color="primary">
              Generate
            </Button>
          </Form>
        )}
      </Formik>
    );
  };

  const renderWeeklyBeneficiaryDynamicReport = () => {
    return (
      <Formik
        initialValues={{ period: "weekly" }}
        onSubmit={onFormSubmit}
        enableReinitialize={true}
        validationSchema={validationSchema}
      >
        {(formik_props) => (
          <Form>
            <Typography variant="h6">Weekly report</Typography>
            {renderDonorDropdownInput({ props: formik_props })}

            <FormikDateTimePicker
              name="select_date"
              label="Select date..."
              helperText="Select any date in the reporting week"
              include_time={false}
              formik_props={formik_props}
            />
            <Button type="submit" variant="contained" color="primary">
              Generate
            </Button>
          </Form>
        )}
      </Formik>
    );
  };

  const renderDonorDropdownInput = ({ props }) => {
    return (
      <FormikDonorAutoCompleteDropdown
        name="donor"
        label="Donor"
        formik_props={props}
      />
    );
  };

  return (
    <>
    { submittingForm && <LoadingModal /> }
      <AdminMainLayout
        breadcrumbs={[
          { name: "admin_home" },
          {
            name: "admin_reports report",
            label: "Reports",
            url: "/admin/reports",
          },
          {
            name: "Dynamic report",
            label: "Dynamic Report",
            url: "/admin/reports/dynamic_reporting",
          },
        ]}
        is_busy={is_busy}
        is_loading={is_loading}
        title="Beneficiary Order Reports"
      >
        {renderCheckboxes()}
        <Card variant="outlined">
          <CardContent>
            <Grid container justifyContent="space-between">
              {reportType == "weekly" && (
                <Grid item xs={12} md={4}>
                  {renderWeeklyBeneficiaryDynamicReport()}
                </Grid>
              )}
              {reportType == "monthly" && (
                <Grid item xs={12} md={4}>
                  {renderMonthlyBeneficiaryDynamicReport()}
                </Grid>
              )}
              {reportType == "date_range" && (
                <Grid item xs={12} md={4}>
                  {renderDateRangeBeneficiaryDynamicReport()}
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </AdminMainLayout>
    </>
  );
}
